import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import locations from 'constantsApp/locationsUS.json';
import { toast } from 'react-toastify';
import { cardTokenFields } from 'constantsApp';
import Papa from 'papaparse';
import numeral from 'numeral';
import AdressForm from './addressForm';
import { typePermissons } from './constants';
import { getRounding } from '../localStorages';

const csvUnparseConfig = {
  quotes: true, // or array of booleans
  quoteChar: '"',
  escapeChar: '"',
  delimiter: ',',
  header: true,
  newline: '\r\n',
  skipEmptyLines: false, // other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
  columns: null, // or array of strings
};

const filter = _.partial(_.filter, locations);

export const getPageTotalCount = ({ totalCount, size, items, page }) => {
  if (!totalCount) return size;
  if (!items || items.length < size) return size * (page + 1);
  return size * (page + 1) + 1;
};

/**
 * convertFilterRequestUrl
 * @param {*} obj : filter object input
 * @param {*} arrNS : list field name will parse not string
 */
export const convertFilterRequestUrl = (obj, arrNS = []) => {
  const arr = _.map(obj, (value, key) => {
    if (value === null) return null;
    if (arrNS.includes(key)) {
      return `${key}: ${value}`;
    }
    return `${key}: "${value}"`;
  });
  return `{${arr.filter(el => !!el).join(', ')}}`;
};

export const convertArrEnumtoString = (value, key) => {
  const arr = _.map(value, itemValue => `${itemValue}`);
  return `${key}:[${arr.filter(el => !!el).join(', ')}]`;
};

export const formatStringUrl = (...args) => {
  let i = 1;
  const str = args[0];

  return str.replace(/\{\}/g, () => args[i++]); // eslint-disable-line
};

export function getCities(state) {
  const resultFilter = filter({ state_abbr: state.toUpperCase() });
  return _.uniqBy(resultFilter, 'city');
}

export function getZips(city, state) {
  return filter({ city, state_abbr: state.toUpperCase() });
}

export const calculateValCallback = ({ success, data }) => {
  if (success) {
    return data;
  }

  return [];
};

export const checkChangeSelectValue = (val1, val2) => {
  if (!val1 && !val2) return false;

  if (val1 && !val2) return true;

  if (!val1 && val2) return true;

  return val1 !== val2.value;
};

export const checkChangeValue = (val1, val2) => {
  if (!val1 && !val2) return false; // val1 and val2 is null, '', false

  return val1 !== val2;
};

export const checkChangeDate = (valDate, strDate) => {
  if (!valDate && !strDate) return false;

  return moment(valDate).format('YYYY-MM-DD') !== moment(strDate).format('YYYY-MM-DD');
};

export const checkChangeSelectField = (val1, val2) =>
  checkChangeValue(val1 ? val1.value : null, val2 ? val2.value : null);

export const handleError = (err, msgCandidate) => {
  if (typeof err === 'string') return err;

  return msgCandidate;
};

export const checkEqualArray = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  const newArr1 = _.orderBy(arr1, ['id'], ['asc']);
  const newArr2 = _.orderBy(arr2, ['id'], ['asc']);

  return newArr1.every((el, index) => el.id === newArr2[index].id);
};

export const checkExistElInArr = (el, nameEl, arr, nameCheck) => {
  const index = arr.findIndex(item => el[nameEl] === item[nameCheck]);
  if (index === -1) return false;
  return true;
};

export function strTwoFractionDigits(number) {
  if (!number) return '0.00';
  return Intl.NumberFormat('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}

export function roundFloat(value, decimals) {
  if (!value) return null;
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals); //eslint-disable-line
}

const parseObjToString = (value, key, arrNS) => {
  if (arrNS.includes(key)) {
    return `${key}: ${value || value === 0 || typeof value === 'boolean' ? value : null}`;
  }
  if (value === null) {
    if (key === 'grants' || key === 'phones') {
      return '';
    }
    return null;
  }
  return `${key}: "${value || ''}"`;
};

const parseArrToString = (value, key, arrNS) => {
  const arr = _.map(value, itemValue => {
    if (!_.isArray(itemValue) && itemValue.length) {
      const temp = [];
      for (const key in itemValue) {
        if (key === 'roles') {
          temp.push(convertArrEnumtoString(itemValue[key], key));
        }
        if (_.isArray(itemValue[key])) {
          temp.push(parseArrToString(itemValue[key], key, arrNS));
        } else {
          temp.push(parseObjToString(itemValue[key], key, arrNS));
        }
      }
      return `{${temp.filter(el => !!el).join(', ')}}`;
    }
    const arrItem = _.map(itemValue, (childValue, childKey) => {
      if (childKey === 'roles') {
        return convertArrEnumtoString(childValue, childKey);
      }

      if (_.isArray(childValue)) {
        return parseArrToString(childValue, childKey, arrNS);
      }

      if (_.isObject(childValue)) {
        return `${childKey}: ${parseToMutationRequest(childValue, arrNS)}`;
      }
      return parseObjToString(childValue, childKey, arrNS);
    });
    return `{${arrItem.filter(el => !!el).join(', ')}}`;
  });
  return `${key}: [${arr.filter(el => !!el).join(', ')}]`;
};

/**
 * parseToMutationRequest: used for create or modify
 * @param {*} obj : object input
 * @param {*} arrNS : list field name will parse not string
 */
export const parseToMutationRequest = (obj, arrNS = []) => {
  const arr = _.map(obj, (value, key) => {
    if (value === null) return null;
    if (_.isArray(value)) {
      return parseArrToString(value, key, arrNS);
    }
    if (_.isObject(value)) {
      return `${key}: ${parseToMutationRequest(value, arrNS)}`;
    }
    return parseObjToString(value, key, arrNS);
  });
  return `{${arr.filter(el => !!el).join(', ')}}`;
};

export const parseToTwoLvArrNSMutationRequest = (obj, lv1ArrNS = [], lv2ArrNS = []) => {
  const arr = _.map(obj, (value, key) => {
    if (value === null) return null;
    if (_.isArray(value)) {
      return parseArrToString(value, key, lv2ArrNS);
    }
    if (_.isObject(value)) {
      return `${key}: ${parseToMutationRequest(value, lv2ArrNS)}`;
    }
    return parseObjToString(value, key, lv1ArrNS);
  });
  return `{${arr.filter(el => !!el).join(', ')}}`;
};
/**
 * parseToMutationRequest: used for create or modify
 * @param {*} obj : object input
 * @param {*} arrNS : list field name will parse not string
 */
export const parseToUserManagementMutationRequest = (obj, objectNS = [], arrayNS = []) => {
  const arr = _.map(obj, (value, key) => {
    if (value === null) return null;
    if (_.isArray(value)) {
      return parseArrToString(value, key, arrayNS);
    }
    return parseObjToString(value, key, objectNS);
  });
  return `{${arr.filter(el => !!el).join(', ')}}`;
};

export const getNewIndexFromArr = (arr = []) => {
  const last = _.last(arr);
  if (last) {
    return last.index + 1;
  }

  return 1;
};

/**
 * parseToMutationRequest: used for create or modify
 * @param {*} obj : object input
 * @param {*} arrNS : list field name will parse not string
 */
export const parseToMutationRequestUser = (obj, arrNS = [], isCreate = false) => {
  const objUserDetails = obj;
  if (!isCreate && objUserDetails.category) {
    delete objUserDetails.category;
  }
  const arr = _.map(objUserDetails, (value, key) => {
    if (value === null) return null;
    if (_.isArray(value)) {
      return parseArrToString(value, key, arrNS);
    }
    if (key === 'category') {
      return `${key}: ${value}`;
    }
    if (key === 'type') {
      return `${key}: "${value}"`;
    }
    return parseObjToString(value, key, arrNS);
  });
  return `{${arr.filter(el => !!el).join(', ')}}`;
};

export const convertString2Obj = (url, prefix) => {
  const index = url.lastIndexOf(prefix || 'id=');
  const arrData = index > 0 ? url.slice(index, url.length).split('&') : '';

  return arrData || '';
};

export const getDataCardFromURL = (url, key, prefix) => {
  const arrDataFromURL = convertString2Obj(url, prefix) || [];
  let token = '';
  arrDataFromURL.map(item => {
    if (item.indexOf(key) > -1) {
      const string2Rex = new RegExp(key, 'g');
      if (key === cardTokenFields.expiryDate) {
        token = decodeURIComponent(item.replace(string2Rex, ''));
        const month = token.slice(0, 2);
        token = token.slice(3, token.length);
        token = `${month}/${token}`;
      } else {
        token = item.replace(string2Rex, '');
      }
    }
    return token;
  });
  return token;
};

const convertCurrency2Option = currencies => {
  return currencies.map(item => ({
    label: `${item.name} (${item.code})`,
    value: item.code,
  }));
};

export const validateCustomAttribute = (name, type, mandatory) => values => {
  if (mandatory === 'Y') {
    if (!values) return `Field ${name} must be ${type}  and can not be blank`;
  }

  if (type === 'NUMERIC') {
    if (values && isNaN(values)) {
      return `Field ${name} must be ${type}  `;
    }
  }
  if (type === 'INTEGER') {
    if (values && isNaN(values)) return `Field ${name} must be ${type}`;
  }
  if (type === 'VARCHAR' || type === 'BYTEA' || type === 'JSONB') {
    if (values && typeof values !== 'string') return `Field ${name} must be ${type}  `;
  }
  if (type === 'BOOLEAN') {
    if (values && values !== 'true' && values !== 'false') return `Field ${name} must be ${type}`;
  }

  return undefined;
};

export const getAddressesForm = ({
  country = '',
  state = ' ',
  city = '',
  addresses = {},
  landmarkLabel,
  extralineLabel,
  isSupportRequired,
  isFromAccount,
}) => {
  let AdressFormUS = !_.isEmpty(addresses)
    ? addresses.withAddressesNormalForm
    : AdressForm.withAddressesNormalForm({ landmarkLabel, extralineLabel, isFromAccount });
  if (country === 'USA') {
    AdressFormUS = !_.isEmpty(addresses)
      ? addresses.withAddressesUSForm
      : AdressForm.withAddressesUSForm({ isSupportRequired, isFromAccount });
    _.map(AdressFormUS, (item, index) => {
      if (item.labelFilter && item.labelFilter.indexOf('landmark') > -1 && landmarkLabel) {
        AdressFormUS[index].labelFilter = landmarkLabel;
      }
      if (item.labelFilter && item.labelFilter.indexOf('extraLine') > -1 && extralineLabel) {
        AdressFormUS[index].labelFilter = extralineLabel;
      }

      if (item.fieldSelect && state && item.fieldSelect === 'city') {
        AdressFormUS[index].optionSelect = getCities(state).map(itemCity => ({
          label: itemCity.city,
          value: itemCity.city,
        }));
      }
      if (item.fieldSelect && state && city && item.fieldSelect === 'postalCode') {
        AdressFormUS[index].optionSelect = getZips(city, state).map(itemZip => ({
          label: itemZip.zipcode,
          value: itemZip.zipcode,
        }));
      }
    });
  }
  return _.cloneDeep(AdressFormUS);
};

export const hanldeDataHierarchy = ({ data = [] }) => {
  const newData = data;
  _.map(data, (item, index) => {
    newData[index].firstName = item.contacts[0] && item.contacts[0].firstName;
    newData[index].lastName = item.contacts[0] && item.contacts[0].lastName;
    newData[index].organization = item.contacts[0] && item.contacts[0].organization;
    newData[index].email = item.contacts[0] && item.contacts[0].email;
    newData[index].city = item.addresses[0] && item.addresses[0].city;
    newData[index].state = item.addresses[0] && item.addresses[0].state;
  });
  return newData;
};

export const convertFieldInfo2New = ({ dataField = [], objItemRemove = [], objItemEnable = [] }) => {
  const newDataField = dataField;
  _.remove(newDataField, item => objItemRemove.indexOf(item.labelFilter) > -1);
  const indexEnable = _.findIndex(newDataField, item => objItemEnable.indexOf(item.labelFilter) > -1);
  if (indexEnable !== -1) newDataField[indexEnable].isDisable = false;
  return newDataField;
};

export const messageRoleBilling = ({ roles = [], key = 'BILLING' }) => {
  if (_.isEmpty(roles)) return 'Role should not be empty';
  if (roles.indexOf(key) === -1) return 'Must be check roles BILLING';
  return '';
};

export const isCheckValidateForm = () => {
  const requiredElements =
    document.getElementById('form') && document.getElementById('form').querySelectorAll('[required]')
      ? document.getElementById('form').querySelectorAll('[required]')
      : [];
  let isValidated = false;
  if (!_.isEmpty(requiredElements)) {
    for (let i = 0; i < requiredElements.length; i++) {
      if (!requiredElements[i].value) isValidated = true;
    }
  }
  return isValidated;
};

// Permission user
export const getTypePermission = ({ listPermission, type }) => {
  if (listPermission.length) {
    const itemCheck = listPermission.filter(item => item.permission === type)[0];
    if (itemCheck && itemCheck.type === typePermissons.readOnly) return 1;
    if (itemCheck && itemCheck.type === typePermissons.readWrite) return 2;
  }
  return 0;
};

export const convertPayloadCustomUsageRecCfg = payload => {
  const newPayload = _.cloneDeep(payload);
  newPayload.customRecCfg.forEach((item, index) => {
    if (item && item.customRecAttributesCfg) {
      item.customRecAttributesCfg.forEach((val, idx) => {
        if (val && val.mandatory === true)
          newPayload.customRecCfg[index].customRecAttributesCfg[idx].mandatory = 'true';
        if (val && typeof val.length === 'number')
          newPayload.customRecCfg[index].customRecAttributesCfg[idx].length = `${val.length}`;
        if (val && val.mandatory === false)
          newPayload.customRecCfg[index].customRecAttributesCfg[idx].mandatory = 'false';
        if (val && !val.index) newPayload.customRecCfg[index].customRecAttributesCfg[idx].index = idx + 1;
      });
    }
  });
  return newPayload;
};

export const getLabelOptions = ({ t, item, fieldName, fieldOption }) => {
  try {
    const slt = t ? t(`selections:${fieldOption || fieldName}`)().find(val => val.value === item[fieldName]) : '';
    return slt ? slt.label : '';
  } catch (error) {
    console.log(error);
  }
};

export const getTableFromResponeCollections = (array, key) => {
  if (!array || !Array.isArray(array) || array.length <= 0 || !array[0]) return [];
  return array[0][key] || [];
};

export const dataExpression2Translation = (data, t) => {
  const recordExpression = t('selections:recordExpression')();
  const fieldExpression = t('selections:fieldExpression')();
  let newData = data;
  fieldExpression.forEach(elm => {
    if (newData && newData.indexOf(elm.value) > -1) {
      newData = newData.replace(RegExp(elm.value, 'g'), elm.label);
    }
  });
  recordExpression.forEach(elm => {
    if (newData && newData.indexOf(elm.value) > -1) {
      newData = newData.replace(RegExp(elm.value, 'g'), elm.label);
    }
  });

  return newData;
};

export const month2Translation = (data, t) => {
  if (!t) {
    return data;
  }
  const monthOfYear = t('selections:monthOfYear')();
  let newData = data.toUpperCase();
  monthOfYear.forEach(elm => {
    if (newData.indexOf(elm.value.toUpperCase()) > -1) {
      newData = newData.replace(RegExp(elm.value.toUpperCase(), 'g'), elm.label.toUpperCase());
    }
  });

  return newData;
};

export const getIndexData = ({ data, index }) => {
  const indexItem = data.findIndex(val => val.index === index);
  return indexItem > -1 ? indexItem : -1;
};

export const injectValueFromConfig = ({ dataConfig, listFields, defaultData }) => {
  const newData = _.cloneDeep(defaultData);
  listFields.forEach(val => {
    const indexDefault = dataConfig.findIndex(item => item.property === val);
    if (indexDefault > -1) newData[val] = dataConfig[indexDefault].value;
  });

  return newData;
};

export const injectValueFromConfigWithLabel = ({ dataConfig, listFields, defaultData }) => {
  const newData = _.cloneDeep(defaultData);
  listFields.forEach(val => {
    const indexDefault = dataConfig.findIndex(item => item.property === val.label);
    if (indexDefault > -1) newData[val.value] = dataConfig[indexDefault].value;
  });

  return newData;
};

export const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export default {
  convertCurrency2Option,
  getCities,
};

export const getBase64 = (url, cb) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => cb(reader.result));
  reader.readAsDataURL(url);
};

/**
 * parseToMutationRequest: used for create or modify
 * @param {*} obj : object input
 * @param {*} arrNS : list field name will parse not string
 */

export const convertArrEnumtoStringPostMethod = (value, key) => {
  const arr = _.map(value, itemValue => `${itemValue}`);
  return `${key}:[${arr.filter(el => !!el).join(', ')}]`;
};

const parseObjToStringPostMethod = (value, key, arrNS) => {
  if (arrNS.includes(key)) {
    return `${key}: ${value || value === 0 || typeof value === 'boolean' ? value : null}`;
  }
  if (value === null) {
    if (key === 'grants' || key === 'phones') {
      return '';
    }
    return null;
  }
  return `${key}: \\"${value || ''}\\"`;
};

export const parseToMutationRequestPostMethod = (obj, arrNS = []) => {
  const arr = _.map(obj, (value, key) => {
    if (value === null) return null;
    if (_.isArray(value)) {
      return parseArrToStringPostMethod(value, key, arrNS);
    }
    if (_.isObject(value)) {
      return `${key}: ${parseToMutationRequestPostMethod(value, arrNS)}`;
    }
    return parseObjToStringPostMethod(value, key, arrNS);
  });
  return `{${arr.filter(el => !!el).join(', ')}}`;
};

const parseArrToStringPostMethod = (value, key, arrNS) => {
  const arr = _.map(value, itemValue => {
    if (!_.isArray(itemValue) && itemValue.length) {
      const temp = [];
      for (const key in itemValue) {
        if (key === 'roles') {
          temp.push(convertArrEnumtoStringPostMethod(itemValue[key], key));
        }
        if (_.isArray(itemValue[key])) {
          temp.push(parseArrToStringPostMethod(itemValue[key], key, arrNS));
        } else {
          temp.push(parseObjToStringPostMethod(itemValue[key], key, arrNS));
        }
      }
      return `{${temp.filter(el => !!el).join(', ')}}`;
    }
    const arrItem = _.map(itemValue, (childValue, childKey) => {
      if (childKey === 'roles') {
        return convertArrEnumtoStringPostMethod(childValue, childKey);
      }

      if (_.isArray(childValue)) {
        return parseArrToStringPostMethod(childValue, childKey, arrNS);
      }
      return parseObjToStringPostMethod(childValue, childKey, arrNS);
    });
    return `{${arrItem.filter(el => !!el).join(', ')}}`;
  });
  return `${key}: [${arr.filter(el => !!el).join(', ')}]`;
};

export const convertBooleanObject = data => {
  const item = {};
  _.map(data, (value, key) => {
    if (typeof value === 'boolean') {
      item[key] = value === true ? 'TRUE' : 'FALSE';
    } else {
      item[key] = value;
    }
  });

  return item;
};

export const blobToFile = (theBlob, fileName) => {
  const fileOfBlob = new File([theBlob], fileName, { type: theBlob.type, lastModified: theBlob.lastModified });
  return fileOfBlob;
};

export const blobToCSVFile = ({ fileName, data, columns, t, listOptionFields, replaceHeaderList }) => {
  const newData = [];
  _.map(data, value => {
    const newVal = _.cloneDeep(value);
    const newValConvert = {};
    columns.forEach(cln => {
      newVal[cln.name] = value[cln.name] || value[cln.name] === 0 ? value[cln.name] : '';
      if (listOptionFields && listOptionFields[cln.name] && value[cln.name] && !value?.isNoEnum) {
        const optionsList = t(`selections:${listOptionFields[cln.name]}`)();
        if (cln.name === 'cutOffDom' || cln.name === 'cutOffDOM') {
          const itemSelect = optionsList.find(op => op.value === `${value[cln.name]}`);
          newVal[cln.name] = itemSelect?.label ? itemSelect.label : '';
        } else {
          const itemSelect = optionsList.find(op => op.value === value[cln.name]);
          newVal[cln.name] = itemSelect?.label ? itemSelect.label : '';
        }
      }
      if (typeof value[cln.name] === 'boolean') {
        newVal[cln.name] = value[cln.name] ? 'TRUE' : 'FALSE';
      }
    });
    _.map(newVal, (vl, key) => {
      const itemSelect = columns.find(cl => cl.name === key);
      if (itemSelect && itemSelect.label) {
        newValConvert[t(itemSelect.exportLabel || itemSelect.label)] = vl === 0 ? '0' : vl;
      } else {
        newValConvert[key] = vl === 0 ? '0' : vl;
      }
    });
    newData.push(newValConvert);
  });

  let dataString = Papa.unparse(
    { fields: columns.map(val => t(val.label)), data: newData },
    {
      ...csvUnparseConfig,
    }
  );
  if (replaceHeaderList && replaceHeaderList.length) {
    replaceHeaderList.forEach(hd => {
      dataString = dataString.replace(hd, '');
    });
  }
  const blob = new Blob([dataString], { type: 'text/csv;charset=utf-8' });
  const fileOfBlob = new File([blob], fileName, { type: blob.type, lastModified: blob.lastModified });
  return fileOfBlob;
};

export const formatMoneyValue = (value, isNoNeedPrefix = false) => {
  if (value === null) return value;
  if (typeof value !== 'number' || Number.isNaN(value)) return '0.00';
  if (isNoNeedPrefix) {
    return numeral(value || '0').format('0,0');
  }
  if (value % 1 === 0) {
    return numeral(value || '0').format('0,0');
  }

  return numeral(value || '0').format(`0,0[.][${_.padStart('', countDecimals(value), '0')}]`);
  // return numeral(value || '0').format(
  //   `0,0[.][${_.padStart('', Number.isNaN(getRounding()) ? 2 : getRounding(), '0')}]`
  // );
};

export const formatNumberValue = value => {
  if (value === null) return value;
  if (typeof value !== 'number') return value;
  if (value % 1 === 0) {
    return numeral(value || '0').format('0,0');
  }
  return numeral(value || '0').format(`0,0[.][${_.padStart('', countDecimals(value), '0')}]`);

  // return numeral(value || '0').format(
  //   `0,0[.][${_.padStart('', Number.isNaN(getRounding()) ? 2 : getRounding(), '0')}]`
  // );
};

export const formatNumberValueChart = value => {
  if (value === null) return value;
  if (typeof value !== 'number') return value;
  if (value % 1 === 0) {
    return numeral(value || '0').format('0,0');
  }

  return numeral(value || '0').format(`0,0[.][${_.padStart('', 2, '0')}]`);
};

export const splitF = pth => {
  let fName = 'file_download';
  if (pth && typeof pth === 'string') {
    fName = pth.substring(pth.lastIndexOf('/') + 1, pth.length);
  }

  return fName;
};

export const formatMoneyValueToExport = value => {
  if (value === null) return '';
  if (value === 'undefined') return '';
  if (typeof value !== 'number' || Number.isNaN(value)) return value;
  return Number.parseFloat(value);
};

export const formatNumberCaculation = value => {
  if (value === null) return value;
  if (typeof value !== 'number' || Number.isNaN(value)) return value;
  return Number.parseFloat(
    Number.parseFloat(value).toFixed(Number.isNaN(getRounding()) ? 2 : Number.parseInt(getRounding(), 10))
  );
};

export const getMaxMinData = (data, isMoney) => {
  let max = 0;
  let min = 0;
  let total = 0;
  if (!data || !data.length) return { max, min, avg: total };
  if (data && data.length > 1) min = data[0];
  data.forEach(val => {
    if (val > max) max = val;
    if (val < min) min = val;
    total += val;
  });
  return {
    max: isMoney ? formatMoneyValue(max) : formatNumberValueChart(max),
    min: isMoney ? formatMoneyValue(min) : formatNumberValueChart(min),
    avg: isMoney ? formatMoneyValue(total / data.length) : formatNumberValueChart(total / data.length),
  };
};

export const messageCSVStamping = content => {
  if (content && content.search(/\r/) === -1) return <div>{content}</div>;
  const newContent = content ? content.split(/\r/g) : content;
  return <div>{newContent && newContent.length ? newContent.map(val => <div>{val}</div>) : ''}</div>;
};

export const validateNewLine = text => {
  if (!text) return false;
  const re = /\r|\n/;
  return !!re.exec(String(text).toLowerCase());
};

export const redFontExcelFile = { font: { color: { rgb: 'FFFF0000' } } };
export const redFontPdfFile = { textColor: '#D00000' };
export const totalFontPdfFile = { fillColor: '#00A651', textColor: '#FFFFFF', fontStyle: 'bold' };
export const messageNotesBills = content => {
  const newContent = content ? content.split('|') : content;
  return (
    <div className="note-content-stream">
      {newContent && newContent.length ? newContent.map(val => <div>{val}</div>) : ''}
    </div>
  );
};

export const compareListFields = ({ columns, responseFields, t }) => {
  return responseFields.map(val => {
    const itemSelect = columns.find(cl => cl.name === val.name);
    if (itemSelect) {
      return itemSelect;
    }
    return {
      label: t && t(`label.${[val.name]}`) !== `label.${val.name}` ? t(`label.${[val.name]}`) : val.name,
      name: val.name,
    };
  });
};

export const configOutputTemplateByType = ({ columns, data, t }) => {
  // const sourceData = data.source || [];
  // const targetData = data.target || [];
  // const allOfFields = [...sourceData, ...targetData];
  // const noColumnsAdded =
  //   columns && columns.length
  //     ? columns.filter(val => allOfFields.findIndex(field => field.name === val.name) === -1)
  //     : [];
  const exportColumns = {
    sourceFields: {
      name: 'label.sourceFields',
      items: compareListFields({
        columns,
        responseFields: data.source ? [...data.source] : [],
        t,
      }),
    },
    targetFields: {
      name: 'label.targetFields',
      items: compareListFields({ columns, responseFields: data.target || [], t }),
    },
  };
  return exportColumns;
};

const parseObjToStringBulk = (value, key, arrNS) => {
  if (arrNS.includes(key)) {
    return `\\"${key}\\": ${value || value === 0 || typeof value === 'boolean' ? value : null}`;
  }
  return `\\"${key}\\": \\"${value || ''}\\"`;
};

const parseArrToStringBulk = (value, key, arrNS) => {
  const arr = _.map(value, itemValue => {
    if (!_.isArray(itemValue) && itemValue.length) {
      const temp = [];
      for (const key in itemValue) {
        if (_.isArray(itemValue[key])) {
          temp.push(parseArrToStringBulk(itemValue[key], key, arrNS));
        } else {
          temp.push(parseObjToStringBulk(itemValue[key], key, arrNS));
        }
      }
      return `{${temp.filter(el => !!el).join(', ')}}`;
    }
    const arrItem = _.map(itemValue, (childValue, childKey) => {
      if (_.isArray(childValue)) {
        return parseArrToStringBulk(childValue, childKey, arrNS);
      }

      if (_.isObject(childValue)) {
        return `${childKey}: ${parseToMutationRequestBulk(childValue, arrNS)}`;
      }
      return parseObjToStringBulk(childValue, childKey, arrNS);
    });
    return `{${arrItem.filter(el => !!el).join(', ')}}`;
  });
  return `\\"${key}\\": [${arr.filter(el => !!el).join(', ')}]`;
};

export const parseToMutationRequestBulk = (obj, arrNS = []) => {
  if (typeof obj === 'string') {
    return { payload: `\\"${obj || ''}\\"` };
  }
  const arr = _.map(obj, (value, key) => {
    if (value === null) return null;
    if (_.isArray(value)) {
      return parseArrToStringBulk(value, key, arrNS);
    }
    if (_.isObject(value)) {
      return `${key}: ${parseToMutationRequestBulk(value, arrNS)}`;
    }

    return parseObjToStringBulk(value, key, arrNS);
  });
  return { payload: `{${arr.filter(el => !!el).join(', ')}}` };
};

export const downloadStringToFile = ({ text, fileType, fileName }) => {
  const blob = new Blob([text], { type: fileType });
  const a = document.createElement('a');
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(() => {
    URL.revokeObjectURL(a.href);
  }, 1500);
};

export const getLastIndex = ({ data }) => {
  let lastIndex = 0;
  if (data && data.length) {
    data.forEach(val => {
      if (val.index > lastIndex) lastIndex = val.index;
    });
  }
  return lastIndex + 1;
};

export const supportRemoveItemWithIndex = ({ data, keyCheck }) => {
  const newData = data && data.length ? data.filter(r => r.isNew) : [];
  const oldData = data && data.length ? data.filter(r => !r.isNew) : [];
  let allData = [];
  allData = [
    ...oldData.map(r => (r[keyCheck] ? { index: r.index, [keyCheck]: r[keyCheck] } : { index: r.index })),
    ...newData.map((r, idx) => ({ index: idx + getLastIndex({ data: oldData }), [keyCheck]: r[keyCheck] })),
  ];
  return allData;
};

export const validate = (out = false, formRef, t) => {
  const formValid = formRef && formRef.current && formRef.current.checkValidity();
  if (!formRef.current) return true;
  const { elements } = formRef.current;
  for (let i = 0; i < elements.length; i++) {
    if (!elements[i].validity.valid) {
      console.log(elements[i].name, 'invalid');
    }
  }
  if (!formValid && out) {
    toast.error(t('common:message.mandatory'));
  }
  return formValid;
};

export const convertIdPOAndDO = (name, prefix, index) => {
  return `${name.toLowerCase().replace(/ /g, '_')}_${prefix}${index || index === 0 ? index + 1 : ''}`;
};

export const convertBooleanDataView = value => {
  if (typeof value === 'boolean') {
    return value === true ? 'TRUE' : 'FALSE';
  }
  if (value === 'true' || value === 'false') {
    return value === 'true' ? 'TRUE' : 'FALSE';
  }
  return value;
};

export const convertLabelWithExistOptions = ({ defaultOptions, newOptions }) => {
  const options = [];
  if (newOptions && newOptions.length) {
    newOptions.forEach(val => {
      const itemSelect = defaultOptions.find(i => i.value === val.value);
      if (itemSelect) {
        options.push(itemSelect);
      } else {
        options.push(val);
      }
    });
  }
  return options;
};

export const bodyToHeaderFontPdfFile = { fillColor: 'white', fontStyle: 'bold' };

export const compareModifyObj = ({ defaultObject, objChange }) => {
  const data = {};
  if (defaultObject) {
    _.map(defaultObject, (value, key) => {
      if (objChange[key] !== value && objChange[key]) {
        data[key] = objChange[key];
      }
    });
  }
  return data;
};

export const supportRemoveIndex = ({ data, keyCheck }) => {
  const newData = data && data.length ? data.filter(r => r.isNew) : [];
  const oldData = data && data.length ? data.filter(r => !r.isNew) : [];
  let allData = [];
  allData = [
    ...oldData.map(r => {
      const { isNew, ...rest } = r;
      return r[keyCheck] ? { ...rest } : { index: r.index };
    }),
    ...newData.map((r, idx) => {
      const { index, isNew, ...rest } = r;
      return { index: idx + getLastIndex({ data: oldData }), ...rest };
    }),
  ];
  return allData;
};

export const supportRemoveIndexWithSize = ({ data }) => {
  const newData = data && data.length ? data.filter(r => r.isNew) : [];
  const oldData = data && data.length ? data.filter(r => !r.isNew) : [];
  let allData = [];
  allData = [
    ...oldData.map(r => {
      const { isNew, ...rest } = r;
      return _.size(r) > 1 ? { ...rest } : { index: r.index };
    }),
    ...newData.map((r, idx) => {
      const { index, isNew, ...rest } = r;
      return { index: idx + getLastIndex({ data: oldData }), ...rest };
    }),
  ];
  return allData;
};

export const supportRemoveLastIndex = ({ data }) => {
  let allData = [];
  let dataLastIndex = [];
  if (data && data.length) {
    allData = data.map((val, idx) => {
      const { isNew, index, ...rest } = val;
      if (index > data.length) dataLastIndex = [...dataLastIndex, { index }];

      return { ...rest, index: idx + 1 };
    });
  }

  return [...allData, ...dataLastIndex];
};

export const supportShowNumberValue = value => {
  if (value || typeof value === 'number') {
    return value;
  }
  return null;
};

export const add3Dots = (string, limit) => {
  const dots = '...';
  let newString = string;
  if (string.length > limit) {
    newString = string.substring(0, limit) + dots;
  }

  return newString;
};

export const compareArrayIndexValueChange = ({ defaultData, newData }) => {
  let newDataChange = null;
  if (newData && newData.length) {
    if (!newDataChange) newDataChange = [];
    newData.forEach(val => {
      const { index } = val;
      const itemSelect = defaultData ? defaultData.find(val => val.index === index) : null;
      if (itemSelect && !_.isEqual(itemSelect, val)) {
        newDataChange.push(val);
      }
      if (!itemSelect) {
        newDataChange.push(val);
      }
    });
  }

  return newDataChange;
};

export const checkPermissionWithRoutes = ({ permissions, links }) => {
  const newLinks = [];
  const newPermission = _.cloneDeep(permissions);

  if (links && links.length) {
    links.forEach(link => {
      const { moduleKey, moduleName, subPermissionKey, ...rest } = link;
      const per = newPermission[moduleKey].find(val => val.moduleName === moduleName);
      if (
        per &&
        per[subPermissionKey] &&
        per[subPermissionKey].length &&
        per[subPermissionKey].find(i => i.type !== 'NONE')
      ) {
        newLinks.push(rest);
      }
    });
  }
  return newLinks;
};

export const isHasPermissionWithMenu = ({ permissions, listKeys }) => {
  let isHas = false;
  const newPermission = _.cloneDeep(permissions);
  const { subPermissionKey } = listKeys;
  if (
    newPermission &&
    newPermission[subPermissionKey] &&
    newPermission[subPermissionKey].length &&
    newPermission[subPermissionKey].find(i => i.type !== 'NONE')
  ) {
    isHas = true;
  }
  return isHas;
};

export const isHasPermissionWithModule = ({ permissions, listKeys }) => {
  let isHas = false;
  const newPermission = _.cloneDeep(permissions);
  if (newPermission) {
    if (_.isArray(newPermission)) {
      _.map(newPermission, value => {
        if (_.isObject(value)) {
          _.map(value, (list, key) => {
            if (_.isArray(list) && listKeys && listKeys.includes(key)) {
              if (!isHas) {
                isHas = !!list.find(val => val && val.type !== 'NONE');
              }
            }
          });
        }
      });
    }
  }

  return isHas;
};

export const convertBodyCSVFile = ({ item, columnsTable, t }) => {
  const newItem = [];
  if (item && typeof item === 'object') {
    columnsTable.forEach(val => {
      if (val.name === 'cutOffDOM') {
        if (item.cutOffDOM === -2) {
          const cutOffDOMOptions = t('selections:cutOffDOM')();
          const lastItem = cutOffDOMOptions[0];
          newItem.push({ content: lastItem.label || '', ...val });
        } else newItem.push({ content: `${item.cutOffDOM || ''}` });
      }
      if (val.name !== 'cutOffDOM') {
        if (item[val.name] && typeof item[val.name] === 'number') {
          newItem.push({
            content: formatMoneyValueToExport(item[val.name]),
            styles: val.isSupportRedFront && item[val.name] < 0 ? redFontPdfFile : null,
            ...val,
          });
        } else if (val.isRenderT) {
          newItem.push({
            content: getLabelOptions({
              item,
              t,
              fieldOption: val.fieldOption || null,
              fieldName: val.name,
            }),
            ...val,
          });
        } else if (typeof item[val.name] === 'boolean') {
          newItem.push({ content: item[val.name] ? 'TRUE' : 'FALSE' });
        } else {
          newItem.push({
            content: item[val.name] && typeof item[val.name] !== 'object' ? item[val.name] : '',
            ...val,
          });
        }
      }
    });
  }
  return newItem;
};

export const blobToCSVFileRoteColumns = ({ fileName, data, columns, t, replaceHeaderList }) => {
  const Header = columns.map(val => t(val.exportLabel) || t(val.label));
  const newListData = data.map(item => {
    return convertBodyCSVFile({ item, columnsTable: columns, t });
  });

  const newHeader = [];
  const newBody = [];
  Header.forEach((hd, index) => {
    if (index === 0) {
      newHeader.push(hd);
    } else {
      if (!newBody[index - 1]) newBody[index - 1] = [];
      newBody[index - 1].push(hd);
    }
  });
  newListData.forEach(dt => {
    if (dt && dt.length) {
      dt.forEach((val, idx) => {
        if (idx === 0) {
          newHeader.push(val.content);
        } else {
          if (!newBody[idx - 1]) newBody[idx - 1] = [];
          newBody[idx - 1].push(val.content);
        }
      });
    }
  });

  let dataString = Papa.unparse(
    { fields: newHeader, data: newBody },
    {
      ...csvUnparseConfig,
    }
  );
  if (replaceHeaderList && replaceHeaderList.length) {
    replaceHeaderList.forEach(hd => {
      dataString = dataString.replace(hd, '');
    });
  }
  const blob = new Blob([dataString], { type: 'text/csv;charset=utf-8' });
  const fileOfBlob = new File([blob], fileName, { type: blob.type, lastModified: blob.lastModified });
  return fileOfBlob;
};

export const countDecimals = value => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

export const mobileCheck = () => {
  let check = false;
  (a => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
