import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getJobScheduleConfig,
  modifyJobConfig,
  getJobScheduleByDate,
  createTenantSchedule,
  getJobErrors,
  modifyJobSchedule,
  getJobErrorDetails,
  processJobSchedule,
  retryJobSchedule,
  updateJobScheduleStatus,
  getCcpDateTime,
  createJobConfig,
  recreateJobSchedule,
  resetSubsequentJobsFromErrorJob,
  getJobScheduleConfigByFrequency,
} from '../../api';
import { isConnecting, isEndConnected } from '../Loader/actions';
import { queryRequest, mutationRequest } from '../../utils/reuqestJob';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

// ------ get jobconfig
export function* getJobConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  const { query } = payload;
  try {
    const response = yield call(queryRequest, getJobScheduleConfig(query));
    if (response.getJobScheduleConfig && response.getJobScheduleConfig.configJobList) {
      yield put({
        type: types.GET_JOBCONFIG_SUCCESS,
        payload: response.getJobScheduleConfig || [],
      });
    } else {
      // toast.error('Job config is empty!');
    }
    if (cb) {
      cb({ success: true, isCreate: !response.getJobScheduleConfig });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// ------ modify jobconfig
export function* modifyJobConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { jobConfig } = payload;
    const response = yield call(mutationRequest, modifyJobConfig(jobConfig));
    if (response.modifyJobConfig && response.modifyJobConfig.configJobList) {
      toast.success(i18n.t('common:apiMessage.modifyJobConfigSuccessfully'));
    } else {
      toast.error('Failed to update jobconfig. Please try again or check your network!');
    }
    if (cb) {
      cb({ success: true });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// ------ create jobconfig
export function* createJobConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { jobConfig } = payload;
    const response = yield call(mutationRequest, createJobConfig(jobConfig));
    if (response.createJobConfig && response.createJobConfig.configJobList) {
      // yield put(actions.getJobConfig(''));
      toast.success(i18n.t('common:apiMessage.createJobConfigSuccessfully'));
    }
    if (cb) {
      cb({ success: true, data: response.createJobConfig });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// ------ get jobSchedule
export function* getJobScheduleSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getJobScheduleByDate(payload));
    yield put({
      type: types.GET_JOBSCHEDULE_SUCCESS,
      payload: response.getJobScheduleByDateAndFrequencyAndName,
    });
    // if (response.getJobScheduleByDate) {
    // }
    // else {
    //   toast.error('There is no job schedule. Please create new job schedule!');
    // }
    if (cb) {
      cb({ success: true, data: response.getJobScheduleByDateAndFrequencyAndName });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// ------ modify jobSchedule
export function* modifyJobScheduleSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    // const { date } = payload;
    const response = yield call(mutationRequest, modifyJobSchedule(payload));
    if (response.modifyJobSchedule) {
      yield put({
        type: types.MODIFY_JOBSCHEDULE_SUCCESS,
        payload: response.modifyJobSchedule,
      });
      toast.success(i18n.t('common:apiMessage.modifyJobScheduleSuccessfully'));
    } else {
      toast.error('Failed to update job schedule. Please try again or check your network!');
    }
    if (cb) {
      cb({ success: true });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// ------ process jobSchedule
export function* processJobScheduleSaga({ payload, cb, isProcessAll }) {
  yield put(isConnecting());
  try {
    yield call(mutationRequest, processJobSchedule(payload));
    toast.success(i18n.t('common:apiMessage.processJobScheduleSucessfully'));

    if (cb) {
      cb({ success: true });
    }
    if (isProcessAll) {
      yield put({
        type: types.PROCESS_ALL_JOBSCHEDULE_SUCCESS,
      });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// ------ retry jobSchedule
export function* retryJobScheduleSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    yield call(mutationRequest, retryJobSchedule(payload));
    toast.success(i18n.t('common:apiMessage.retryJobScheduleSucessfully'));
    if (cb) {
      cb({ success: true });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// ------ update jobSchedule status
export function* updateStatusJobScheduleSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, updateJobScheduleStatus(payload));
    if (response.updateJobScheduleStatus) {
      // yield put({
      //   type: types.UPDATE_STATUS_JOBSCHEDULE_SUCCESS,
      //   payload: response.updateJobScheduleStatus,
      // });
    } else {
      toast.error("Failed to update job's status. Please try again or check your network!");
    }
    if (cb) {
      cb({ success: true });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// ------ get job errors
export function* getJobErrorsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { type, scheduleDate } = payload;
    const response = yield call(queryRequest, getJobErrors({ type, scheduleDate }));
    const { jobErrorGroupList, jobId } = response.getJobErrors;
    if (response) {
      yield put({
        type: types.GET_JOB_ERRORS_SUCCESS,
        payload: {
          [jobId]: jobErrorGroupList,
        },
      });
      if (cb) cb({ success: true, data: response.getJobErrors });
    } else {
      toast.error('Failed to get information of job errors. Please try again or check your network!');
    }
    yield put(isEndConnected());
  } catch (error) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// ------ create job schedule by date
export function* createJobScheduleSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { scheduleDate, userId, scheduleFrequency, scheduleName } = payload;
    const response = yield call(
      mutationRequest,
      createTenantSchedule({
        scheduleDate,
        userId,
        scheduleFrequency,
        scheduleName,
      })
    );
    if (response.createTenantSchedule) {
      yield put({
        type: types.CREATE_JOBSCHEDULE_SUCCESS,
        payload: response.createTenantSchedule,
      });
      toast.success(i18n.t('common:apiMessage.createTenantScheduleSucessfully'));
    } else {
      toast.error('Failed to create Job schedule. Please try again or check your network!');
    }
    if (cb) {
      cb({ success: true });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: true });
    }
    yield put(isEndConnected());
  }
}

export function* getJobErrorDetailsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { scheduleDate, type, reason } = payload;
    const response = yield call(queryRequest, getJobErrorDetails({ type, scheduleDate, reason }));
    const { jobs } = response.getJobErrorDetails;
    if (cb && jobs) {
      cb(jobs);
    } else {
      // toast.error('Failed to create job error details. Please try again or check your network!');
    }
    yield put(isEndConnected());
  } catch (error) {
    yield put(isEndConnected());
  }
}

export function* getCcpDateTimeSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCcpDateTime({}));
    const { ccpTime } = response.getCcpDateTime;
    if (cb && ccpTime) {
      cb(ccpTime);
    } else {
      yield put({
        type: types.GET_JOB_CCP_DATETIME_SUCCESS,
        paylouad: { ccpTime },
      });
    }
    yield put(isEndConnected());
  } catch (error) {
    yield put(isEndConnected());
  }
}

// ------ recreate jobSchedule
export function* recreateJobScheduleSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, recreateJobSchedule(payload));
    toast.success(i18n.t('common:apiMessage.reCreateJobScheduleSucessfully'));
    if (response.recreateJobSchedule) {
    } else {
      // toast.error('Failed to recreate job schedule!');
    }
    if (cb) {
      cb({ success: true });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
      // toast.error('Failed to recreate job schedule!');
    }
    yield put(isEndConnected());
  }
}

export function* resetSubsequentJobsFromErrorJobSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, resetSubsequentJobsFromErrorJob(payload));
    toast.success(i18n.t('common:apiMessage.resetSubsequentJobsFromErrorJobSucessfully'));
    if (response.resetSubsequentJobsFromErrorJob) {
      if (cb) {
        cb({ success: true });
      }
    } else if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getJobScheduleConfigByFrequencySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getJobScheduleConfigByFrequency(payload));
    if (response.getJobScheduleConfigByFrequency && response.getJobScheduleConfigByFrequency) {
      if (payload === 'DAILY') {
        yield put(actions.getJobScheduleConfigByFrequencyDailySuccess(response.getJobScheduleConfigByFrequency || []));
      }
      if (payload === 'SCHEDULED') {
        yield put(
          actions.getJobScheduleConfigByFrequencyScheduledSuccess(response.getJobScheduleConfigByFrequency || [])
        );
      }
      if (cb) yield cb({ success: true, data: response.getJobScheduleConfigByFrequency });
    } else {
      if (payload === 'DAILY') {
        yield put(actions.getJobScheduleConfigByFrequencyDailySuccess([]));
      }
      if (payload === 'SCHEDULED') {
        yield put(actions.getJobScheduleConfigByFrequencyScheduledSuccess([]));
      }
      if (cb) yield cb({ success: false });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (payload === 'DAILY') {
      yield put(actions.getJobScheduleConfigByFrequencyDailySuccess([]));
    }
    if (payload === 'SCHEDULED') {
      yield put(actions.getJobScheduleConfigByFrequencyScheduledSuccess([]));
    }
    yield put(isEndConnected());
    if (cb) yield cb({ success: false });
  }
}

export default function* collectionsSaga() {
  yield takeLatest(types.GET_JOBCONFIG, getJobConfigSaga);
  yield takeLatest(types.MODIFY_JOBCONFIG, modifyJobConfigSaga);
  yield takeLatest(types.GET_JOBSCHEDULE, getJobScheduleSaga);
  yield takeLatest(types.RECREATE_JOBSCHEDULE, recreateJobScheduleSaga);
  yield takeLatest(types.CREATE_JOBSCHEDULE, createJobScheduleSaga);
  yield takeLatest(types.MODIFY_JOBSCHEDULE, modifyJobScheduleSaga);
  yield takeLatest(types.PROCESS_JOBSCHEDULE, processJobScheduleSaga);
  yield takeLatest(types.RETRY_JOBSCHEDULE, retryJobScheduleSaga);
  yield takeLatest(types.UPDATE_STATUS_JOBSCHEDULE, updateStatusJobScheduleSaga);
  yield takeLatest(types.GET_JOB_ERRORS, getJobErrorsSaga);
  yield takeLatest(types.GET_JOB_ERROR_DETAILS, getJobErrorDetailsSaga);
  yield takeLatest(types.GET_JOB_CCP_DATETIME, getCcpDateTimeSaga);
  yield takeLatest(types.CREATE_JOBCONFIG, createJobConfigSaga);
  yield takeLatest(types.RESET_SUBSEQUENT_JOBS_FROM_ERROR_JOB, resetSubsequentJobsFromErrorJobSaga);
  yield takeEvery(types.GET_JOB_SCHEDULE_CONFIG_BY_FREQUENCY, getJobScheduleConfigByFrequencySaga);
}
