import React, { useState, useEffect, useRef } from 'react';
import PropsType from 'prop-types';
import { map, isEmpty, cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import arrayMove from 'array-move';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import { GenericInput, DataTablDragDrop, ModalViewNote } from '../../../components/common';
import { validate, getLastIndex } from '../../../utils/utils';
import ModalExpression from './ModalExpression';
import RouteNames from '../../App/RouteNames';

let listOptionsAdd = [];

const messageNotesTaxType = t => (
  <div>
    <span>{t('message.messageNotesTaxType1')}</span>
    <Link to={RouteNames.taxTypeConfig.path} className="text-success ml-2 mr-2">
      {t('message.messageNotesTaxType2')}
    </Link>
    <span>{t('message.messageNotesTaxType3')}</span>
  </div>
);

const onValidTaxes = data => {
  let isNoValid = false;
  if (data && data.length) {
    data.forEach((val, index) => {
      data.forEach((item, idx) => {
        if (idx < index && item.taxBase && item.taxBase.indexOf(val.name) !== -1) {
          isNoValid = true;
        }
      });
    });
  }
  return isNoValid;
};

const ModalTaxes = ({
  title,
  isOpen,
  onToggle,
  data,
  dataField,
  isSearch,
  onSave,
  wrapperClass,
  modeModifyTaxConfig,
  taxTypesList,
  isAccumulatorBasedTaxThresholds,
  accumulatorList,
}) => {
  const formRef = useRef();
  const { t } = useTranslation('common');
  const [itemData, setData] = useState(cloneDeep(data));
  const [wasValidated, setValidate] = useState(false);
  const [isOpenModalExpression, setIsOpenModalExpression] = useState(false);
  const [indexItemSelect, setIndexItemSelect] = useState(null);
  const [isFocusTaxes, setIsFocusTaxes] = useState(false);
  const [isOpenModalNotes, setIsOpenModalNotes] = useState(false);
  const [message, setMessage] = useState('');

  const onChangeData = ({ name, value }) => {
    const newData = cloneDeep(itemData);
    newData[name] = value;
    setData(newData);
  };

  const onToggleModal = () => {
    setIsOpenModalExpression(!isOpenModalExpression);
  };

  const onChangeTaxes = ({ name, value, index }) => {
    const newData = cloneDeep(itemData);
    newData.productTaxes[index][name] = value;
    setData(newData);
  };

  const onApplyExpression = ({ data }) => {
    try {
      const newData = cloneDeep(itemData);
      newData.productTaxes[indexItemSelect].taxBase = data;
      setIsOpenModalExpression(!isOpenModalExpression);
      setIndexItemSelect(null);
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = evt => {
    evt.preventDefault();
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    setValidate(false);
    if (itemData.productTaxes && itemData.productTaxes.length) {
      itemData.productTaxes = itemData.productTaxes.map(val => {
        const { isNew, ...rest } = val;
        return rest;
      });
    }
    return onSave({ data: itemData });
  };

  const onAddNewTaxes = () => {
    const newItemData = cloneDeep(itemData);
    if (!newItemData.productTaxes) newItemData.productTaxes = [];
    const newItem = {
      index: getLastIndex({ data: newItemData.productTaxes }),
      name: null,
      taxBase: 'AMOUNT_TAXED',
      jurisdiction: null,
      jurisdictionList: null,
      excludeList: null,
      description: null,
      startDate: null,
      endDate: null,
      taxPercent: null,
      minimumThreshold: null,
      maximumThreshold: null,
      isNew: true,
    };
    newItemData.productTaxes.push(newItem);
    setData(newItemData);
  };

  const onSelectItem = ({ index }) => {
    onToggleModal();
    setIndexItemSelect(index);
    listOptionsAdd = [];
    if (itemData.productTaxes && itemData.productTaxes.length) {
      itemData.productTaxes.forEach((val, idx) => {
        if (idx < index && val.name) {
          listOptionsAdd.push({ label: val.name, value: val.name });
        }
      });
    }
  };

  const isNoHasName = itemData.productTaxes && itemData.productTaxes.find(val => !val.name);

  useEffect(() => {
    setData(cloneDeep(data));
  }, [data]);

  const options = [...t('selections:taxConfigRegular')(), ...listOptionsAdd];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newData = cloneDeep(itemData);
    const orderedList = arrayMove(newData.productTaxes, oldIndex, newIndex);
    newData.productTaxes = orderedList;
    setData(newData);
  };

  const onRemoveItemTable = ({ index }) => {
    const newData = cloneDeep(itemData);
    newData.productTaxes.splice(index, 1);
    setData(newData);
  };

  let accumulatorColumns = [];
  if (isAccumulatorBasedTaxThresholds) {
    accumulatorColumns = [
      {
        name: 'accumulatorId',
        label: 'label.accumulatorId',
        style: { minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.accumulatorId}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => onChangeTaxes({ name, value, index })}
            name="accumulatorId"
            type="select"
            options={
              accumulatorList
                ? accumulatorList.map(item => ({
                    label: item.accumulatorid,
                    value: item.accumulatorid,
                  }))
                : []
            }
            menuPortalTarget
          />
        ),
      },
      {
        name: 'minAccumulatorThreshold',
        label: 'label.accumulatorMinium',
        style: { minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.minAccumulatorThreshold}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => onChangeTaxes({ name, value, index })}
            name="minAccumulatorThreshold"
            type="number"
          />
        ),
      },
      {
        name: 'maxAccumulatorThreshold',
        label: 'label.accumulatorMaximum',
        style: { minWidth: '160px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.maxAccumulatorThreshold}
            wrapperClass="col-md-12"
            onChange={({ name, value }) => onChangeTaxes({ name, value, index })}
            name="maxAccumulatorThreshold"
            type="number"
          />
        ),
      },
    ];
  }

  const productTaxesColumns = [
    {
      name: 'name',
      label: 'label.name',
      style: { minWidth: '180px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.name}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTaxes({ name, value, index })}
          name="name"
          required
          type="select"
          options={taxTypesList || []}
          menuPortalTarget
        />
      ),
    },
    {
      name: 'description',
      label: 'label.description',
      style: { minWidth: '260px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTaxes({ name, value, index })}
          name="description"
          type="textarea"
        />
      ),
    },
    {
      name: 'taxBase',
      label: 'label.taxBase',
      style: { minWidth: '260px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.taxBase}
          wrapperClass="col-md-12"
          onChange={() => {}}
          name="taxBase"
          required
          fa="fa fa-external-link"
          readOnly={isFocusTaxes}
          type="textarea"
          onClick={() => {
            onSelectItem({ item, index });
            setIsFocusTaxes(true);
          }}
          onBlur={() => setIsFocusTaxes(false)}
        />
      ),
    },
    {
      name: 'taxPercent',
      label: 'label.percent',
      style: { minWidth: '160px' },
      required: true,
      render: (colName, item, index) => (
        <GenericInput
          value={item.taxPercent}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTaxes({ name, value, index })}
          name="taxPercent"
          required
          type="number"
        />
      ),
    },
    {
      name: 'jurisdiction',
      label: 'label.jurisdiction',
      style: { minWidth: '160px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.jurisdiction}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTaxes({ name, value, index })}
          name="jurisdiction"
          tOptions="selections:jurisdiction"
          type="select"
          menuPortalTarget
        />
      ),
    },
    {
      name: 'jurisdictionList',
      label: 'label.jurisdictionList',
      style: { minWidth: '160px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.jurisdictionList}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTaxes({ name, value, index })}
          name="jurisdictionList"
        />
      ),
    },
    {
      name: 'excludeList',
      label: 'label.excludeList',
      style: { minWidth: '160px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.excludeList}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTaxes({ name, value, index })}
          name="excludeList"
        />
      ),
    },
    {
      name: 'minimumThreshold',
      label: 'label.minimumThreshold',
      style: { minWidth: '160px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.minimumThreshold}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTaxes({ name, value, index })}
          name="minimumThreshold"
          type="number"
        />
      ),
    },
    {
      name: 'maximumThreshold',
      label: 'label.maximumThreshold',
      style: { minWidth: '160px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.maximumThreshold}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeTaxes({ name, value, index })}
          name="maximumThreshold"
          type="number"
        />
      ),
    },
    ...accumulatorColumns,
    {
      name: 'startDate',
      label: 'label.startDate',
      style: { minWidth: '230px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.startDate}
          wrapperClass="col-md-12 inner-popover"
          onChange={({ name, value }) => onChangeTaxes({ name, value, index })}
          name="startDate"
          type="date"
        />
      ),
    },
    {
      name: 'endDate',
      label: 'label.endDate',
      style: { minWidth: '230px' },
      render: (colName, item, index) => (
        <GenericInput
          value={item.endDate}
          wrapperClass="col-md-12 inner-popover"
          onChange={({ name, value }) => onChangeTaxes({ name, value, index })}
          name="endDate"
          type="date"
        />
      ),
    },
    {
      name: 'remove',
      label: 'label.remove',
      render: (colName, item, index) => {
        return (
          <div className="form-group col-md-12">
            <button
              disabled={!item.isNew}
              type="button"
              className="btn-phone"
              onClick={() => onRemoveItemTable({ index })}
            >
              <i className="fa fa-trash" />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <Modal isOpen={isOpen} toggle={onToggle} className={`modal-lg info-form ${wrapperClass || ''}`}>
      <form ref={formRef} noValidate className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {isSearch ? (
            <div className="text-center">
              <PulseLoader size={13} color="#28a745" />
            </div>
          ) : (
            <div className="row col-md-12">
              {!isEmpty(dataField) &&
                map(dataField, item => {
                  if (item && item.isSelect) {
                    return (
                      <GenericInput
                        key={item.name}
                        label={t && item.label ? t(item.label) : item.label || ''}
                        type="select"
                        value={itemData[item.name]}
                        onChange={onChangeData}
                        {...item}
                      />
                    );
                  }

                  if (item && item.fieldDate) {
                    return (
                      <GenericInput
                        key={item.name}
                        type="date"
                        isClearable
                        label={t && item.label ? t(item.label) : item.label || ''}
                        dateFormat="yyyy-MM-dd"
                        value={itemData[item.name]}
                        onChange={onChangeData}
                        {...item}
                      />
                    );
                  }

                  return (
                    <GenericInput
                      key={item.name}
                      value={itemData[item.name]}
                      label={t && item.label ? t(item.label) : item.label || ''}
                      type={item.type || 'text'}
                      onChange={onChangeData}
                      {...item}
                    />
                  );
                })}
            </div>
          )}
          <div className="col-md-12 mt-3">
            <h6 className="mb-3 mt-3">
              {t('label.taxCodeTaxes')}
              <button
                type="button"
                className="button button-border gray x-small ml-3 mt-2 mb-2 pl-2 pr-2 pt-0 pb-0"
                onClick={() => {
                  setIsOpenModalNotes(true);
                  setMessage(messageNotesTaxType(t));
                }}
              >
                {t('label.quickNotes')}
              </button>
            </h6>
            <DataTablDragDrop
              columns={productTaxesColumns}
              data={itemData.productTaxes ? itemData.productTaxes || [] : []}
              isSupportIndexStyle
              onSortEnd={onSortEnd}
            />
            <button
              type="button"
              disabled={isNoHasName}
              className="button button-border black x-small mt-3 ml-2"
              onClick={onAddNewTaxes}
            >
              +
              {t('label.addTaxes')}
            </button>
            <button
              type="button"
              disabled={isNoHasName}
              className="button button-border gray x-small "
              onClick={() => {
                setIsOpenModalNotes(true);
                setMessage(t('message.messageQuickTaxes'));
              }}
            >
              {t('label.quickNotes')}
            </button>
          </div>
        </ModalBody>
        <ModalFooter>
          <button onClick={onToggle} type="button" className="button button-border black x-small float-right">
            {t('label.cancel')}
          </button>
          {modeModifyTaxConfig === 2 && (
            <button
              type="button"
              className="button x-small"
              onClick={onSubmit}
              disabled={onValidTaxes(itemData.productTaxes || [])}
            >
              {t('label.saveConfig')}
            </button>
          )}
        </ModalFooter>
      </form>
      <ModalExpression
        onToggle={onToggleModal}
        isOpenModalExpression={isOpenModalExpression}
        onApplyExpression={onApplyExpression}
        data={
          itemData.productTaxes && itemData.productTaxes[indexItemSelect]
            ? itemData.productTaxes[indexItemSelect].taxBase || ''
            : ''
        }
        options={options}
      />
      <ModalViewNote isOpen={isOpenModalNotes} message={message} onToggle={() => setIsOpenModalNotes(false)} />
    </Modal>
  );
};

ModalTaxes.propTypes = {
  onToggle: PropsType.func.isRequired,
  isOpen: PropsType.bool.isRequired,
  title: PropsType.string,
  dataField: PropsType.arrayOf(PropsType.any),
  data: PropsType.objectOf(PropsType.any),
  isSearch: PropsType.bool,
};

ModalTaxes.defaultProps = {
  title: '',
  dataField: [],
  data: {},
  isSearch: false,
};

export default ModalTaxes;
