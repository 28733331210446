import { map, sortBy } from 'lodash';

const tranformObjToArr = obj => {
  return sortBy(
    map(obj, (value, key) => ({ value: key, label: value })),
    ['label']
  );
};

const tranformObjToArrWithNoSort = obj => {
  return map(obj, (value, key) => ({ value: key, label: value }));
};

const tranformObjToArrCutOfDom = obj => {
  let cutOffDOMOptions = map(obj, (value, key) => ({ value: key, label: value }));
  const lastItem = cutOffDOMOptions[cutOffDOMOptions.length - 1];
  cutOffDOMOptions.splice(cutOffDOMOptions.length - 1, 1);
  cutOffDOMOptions = [lastItem, ...cutOffDOMOptions];
  return cutOffDOMOptions;
};

const tranformObjToArrWithUpperCase = obj => {
  return map(obj, (value, key) => ({ value: key.toLocaleUpperCase(), label: value.toLocaleUpperCase() }));
};

const selections = common => ({
  variableTypes: () => tranformObjToArr(common.dropdown.variableTypes),
  status: () => tranformObjToArr(common.dropdown.status),
  paymentTerm: () => tranformObjToArr(common.dropdown.paymentTerm),
  customerSegment: () => tranformObjToArr(common.dropdown.customerSegment),
  accountType: () => tranformObjToArr(common.dropdown.accountType),
  salesChannel: () => tranformObjToArr(common.dropdown.salesChannel),
  marketSegment: () => tranformObjToArr(common.dropdown.marketSegment),
  accountSubType: () => tranformObjToArr(common.dropdown.accountSubType),
  reason: () => tranformObjToArr(common.dropdown.reason),
  phoneType: () => tranformObjToArr(common.dropdown.phoneType),
  billingFrequency: () => tranformObjToArr(common.dropdown.billingFrequency),
  serviceType: () => tranformObjToArr(common.dropdown.serviceType),
  arType: () => tranformObjToArr(common.dropdown.arType),
  types: () => tranformObjToArr(common.dropdown.types),
  statusOrder: () => tranformObjToArr(common.dropdown.statusOrder),
  initialTermUnit: () => tranformObjToArr(common.dropdown.initialTermUnit),
  invoiceType: () => tranformObjToArr(common.dropdown.invoiceType),
  invoiceDelivery: () => tranformObjToArr(common.dropdown.invoiceDelivery),
  paymentMethod: () => tranformObjToArr(common.dropdown.paymentMethod),
  timeUnit: () => tranformObjToArr(common.dropdown.timeUnit),
  paymentMethodAdmin: () => tranformObjToArr(common.dropdown.paymentMethodAdmin),
  paymentWorkingDay: () => tranformObjToArr(common.dropdown.paymentWorkingDay),
  source: () => tranformObjToArr(common.dropdown.source),
  statusPayment: () => tranformObjToArr(common.dropdown.statusPayment),
  action: () => tranformObjToArr(common.dropdown.action),
  statusCollections: () => tranformObjToArr(common.dropdown.statusCollections),
  reasonConfig: () => tranformObjToArr(common.dropdown.reasonConfig),
  CRDRType: () => tranformObjToArr(common.dropdown.CRDRType),
  taxRule: () => tranformObjToArr(common.dropdown.taxRule),
  disputeStatus: () => tranformObjToArr(common.dropdown.disputeStatus),
  statusPricing: () => tranformObjToArr(common.dropdown.statusPricing),
  invoiceStatus: () => tranformObjToArr(common.dropdown.invoiceStatus),
  invoiceUnitType: () => tranformObjToArr(common.dropdown.invoiceUnitType),
  permissionStatus: () => tranformObjToArr(common.dropdown.permissionStatus),
  category: () => tranformObjToArr(common.dropdown.category),
  jobTypes: () => tranformObjToArr(common.dropdown.jobTypes),
  roundingMethod: () => tranformObjToArr(common.dropdown.roundingMethod),
  selectBool: () => tranformObjToArr(common.dropdown.selectBool),
  transactionType: () => tranformObjToArr(common.dropdown.transactionType),
  arCrDrType: () => tranformObjToArr(common.dropdown.arCrDrType),
  paymentTransactionType: () => tranformObjToArr(common.dropdown.paymentTransactionType),
  subscriptionReason: () => tranformObjToArr(common.dropdown.subscriptionReason),
  jobStatus: () => tranformObjToArr(common.dropdown.jobStatus),
  trialLevel: () => tranformObjToArr(common.dropdown.trialLevel),
  trialTypes: () => tranformObjToArr(common.dropdown.trialTypes),
  trialTermUnit: () => tranformObjToArr(common.dropdown.trialTermUnit),
  subscriptionStatus: () => tranformObjToArr(common.dropdown.subscriptionStatus),
  suspenseReason: () => tranformObjToArr(common.dropdown.suspenseReason),
  arLevel: () => tranformObjToArr(common.dropdown.arLevel),
  pricingModel: () => tranformObjToArr(common.dropdown.pricingModel),
  streamName: () => tranformObjToArr(common.dropdown.streamName),
  revenueRecognitionType: () => tranformObjToArr(common.dropdown.revenueRecognitionType),
  revenueType: () => tranformObjToArr(common.dropdown.revenueType),
  recurringUnit: () => tranformObjToArr(common.dropdown.recurringUnit),
  prorationType: () => tranformObjToArr(common.dropdown.prorationType),
  timeUnitPricing: () => tranformObjToArr(common.dropdown.timeUnitPricing),
  tierReference: () => tranformObjToArr(common.dropdown.tierReference),
  tierType: () => tranformObjToArr(common.dropdown.tierType),
  volumeTreatment: () => tranformObjToArr(common.dropdown.volumeTreatment),
  dependencyType: () => tranformObjToArr(common.dropdown.dependencyType),
  recordExpression: () => tranformObjToArr(common.dropdown.recordExpression),
  fieldExpression: () => tranformObjToArr(common.dropdown.fieldExpression),
  selectUnit: () => tranformObjToArr(common.dropdown.selectUnit),
  statusRateUnit: () => tranformObjToArr(common.dropdown.statusRateUnit),
  dayOfWeek: () => tranformObjToArr(common.dropdown.dayOfWeek),
  mandatory: () => tranformObjToArr(common.dropdown.mandatory),
  recordIndicator: () => tranformObjToArr(common.dropdown.recordIndicator),
  moduleName: () => tranformObjToArr(common.dropdown.moduleName),
  merchantName: () => tranformObjToArr(common.dropdown.merchantName),
  statusTenant: () => tranformObjToArr(common.dropdown.statusTenant),
  taxGetWayType: () => tranformObjToArr(common.dropdown.taxGetWayType),
  authType: () => tranformObjToArr(common.dropdown.authType),
  paymentGatewayType: () => tranformObjToArr(common.dropdown.paymentGatewayType),
  financeGatewayType: () => tranformObjToArr(common.dropdown.financeGatewayType),
  accountRole: () => tranformObjToArr(common.dropdown.accountRole),
  selfCareUserType: () => tranformObjToArr(common.dropdown.selfCareUserType),
  dashboardType: () => tranformObjToArr(common.dropdown.dashboardType),
  entityType: () => tranformObjToArr(common.dropdown.entityType),
  recurringType: () => tranformObjToArr(common.dropdown.recurringType),
  rateUnit: () => tranformObjToArr(common.dropdown.rateUnit),
  grantType: () => tranformObjToArr(common.dropdown.grantType),
  monthOfYear: () => tranformObjToArr(common.dropdown.monthOfYear),
  attributeTypes: () => tranformObjToArr(common.dropdown.attributeTypes),
  txnTypeTenant: () => tranformObjToArr(common.dropdown.txnTypeTenant),
  gatewayName: () => tranformObjToArr(common.dropdown.gatewayName),
  arTypeConfig: () => tranformObjToArr(common.dropdown.arTypeConfig),
  itemType: () => tranformObjToArr(common.dropdown.itemType),
  milestoneStatus: () => tranformObjToArr(common.dropdown.milestoneStatus),
  gatewayApi: () => tranformObjToArr(common.dropdown.gatewayApi),
  milestoneUnits: () => tranformObjToArr(common.dropdown.milestoneUnits),
  usageRecStatus: () => tranformObjToArr(common.dropdown.usageRecStatus),
  usageRecIndicator: () => tranformObjToArr(common.dropdown.usageRecIndicator),
  jurisdiction: () => tranformObjToArr(common.dropdown.jurisdiction),
  objectType: () => tranformObjToArr(common.dropdown.objectType),
  exchangeRateType: () => tranformObjToArr(common.dropdown.exchangeRateType),
  billUnitType: () => tranformObjToArr(common.dropdown.billUnitType),
  arOpsType: () => tranformObjToArr(common.dropdown.arOpsType),
  trialStatus: () => tranformObjToArr(common.dropdown.trialStatus),
  accountStatementLineType: () => tranformObjToArr(common.dropdown.accountStatementLineType),
  revenueConfigType: () => tranformObjToArr(common.dropdown.revenueConfigType),
  billStatus: () => tranformObjToArr(common.dropdown.billStatus),
  typeActionBill: () => tranformObjToArr(common.dropdown.typeActionBill),
  adjustmentARType: () => tranformObjToArr(common.dropdown.adjustmentARType),
  disputeARType: () => tranformObjToArr(common.dropdown.disputeARType),
  writeOffARType: () => tranformObjToArr(common.dropdown.writeOffARType),
  costType: () => tranformObjToArr(common.dropdown.costType),
  calendarUnits: () => tranformObjToArr(common.dropdown.calendarUnits),
  calendarStatus: () => tranformObjToArr(common.dropdown.calendarStatus),
  GlAccountType: () => tranformObjToArr(common.dropdown.GlAccountType),
  GlSegmentName: () => tranformObjToArr(common.dropdown.GlSegmentName),
  discountingModel: () => tranformObjToArr(common.dropdown.discountingModel),
  transactionTypeDiscount: () => tranformObjToArr(common.dropdown.transactionTypeDiscount),
  noteStatus: () => tranformObjToArr(common.dropdown.noteStatus),
  arNotesType: () => tranformObjToArr(common.dropdown.arNotesType),
  pricingType: () => tranformObjToArr(common.dropdown.pricingType),
  fileType: () => tranformObjToArr(common.dropdown.fileType),
  categoryUploadFile: () => tranformObjToArr(common.dropdown.categoryUploadFile),
  filePaymentType: () => tranformObjToArr(common.dropdown.filePaymentType),
  paymentStatus: () => tranformObjToArr(common.dropdown.paymentStatus),
  omsTasks: () => tranformObjToArr(common.dropdown.omsTasks),
  apiCategory: () => tranformObjToArr(common.dropdown.apiCategory),
  commonStatus: () => tranformObjToArr(common.dropdown.commonStatus),
  openCloseStatus: () => tranformObjToArr(common.dropdown.openCloseStatus),
  arWriteoffStatus: () => tranformObjToArr(common.dropdown.arWriteoffStatus),
  provisionGatewayUrlType: () => tranformObjToArr(common.dropdown.provisionGatewayUrlType),
  arAction: () => tranformObjToArr(common.dropdown.arAction),
  objectTypeApproval: () => tranformObjToArr(common.dropdown.objectTypeApproval),
  statusOrderLines: () => tranformObjToArr(common.dropdown.statusOrderLines),
  grantTypeConfig: () => tranformObjToArr(common.dropdown.grantTypeConfig),
  creditNoteActions: () => tranformObjToArr(common.dropdown.creditNoteActions),
  objectStatus: () => tranformObjToArr(common.dropdown.objectStatus),
  flexAttribute: () => tranformObjToArrWithNoSort(common.dropdown.flexAttribute),
  mandatoryFlag: () => tranformObjToArr(common.dropdown.mandatoryFlag),
  orderAction: () => tranformObjToArr(common.dropdown.orderAction),
  statusProvisioning: () => tranformObjToArr(common.dropdown.statusProvisioning),
  debitAccountType: () => tranformObjToArr(common.dropdown.debitAccountType),
  accountingRulesObjectType: () => tranformObjToArr(common.dropdown.accountingRulesObjectType),
  barChartDashboardType: () => tranformObjToArr(common.dropdown.barChartDashboardType),
  xaxisParameter: () => tranformObjToArr(common.dropdown.xaxisParameter),
  yaxisParameter: () => tranformObjToArr(common.dropdown.yaxisParameter),
  chargeType: () => tranformObjToArr(common.dropdown.chargeType),
  batchPaymentType: () => tranformObjToArr(common.dropdown.batchPaymentType),
  provisioningLevel: () => tranformObjToArr(common.dropdown.provisioningLevel),
  orderSource: () => tranformObjToArr(common.dropdown.orderSource),
  typeProvisioning: () => tranformObjToArr(common.dropdown.typeProvisioning),
  switchName: () => tranformObjToArr(common.dropdown.switchName),
  paymentAction: () => tranformObjToArr(common.dropdown.paymentAction),
  paymentReversalReason: () => tranformObjToArr(common.dropdown.paymentReversalReason),
  notificationType: () => tranformObjToArr(common.dropdown.notificationType),
  planType: () => tranformObjToArr(common.dropdown.planType),
  deltaPaymentSplitType: () => tranformObjToArr(common.dropdown.deltaPaymentSplitType),
  usageDirection: () => tranformObjToArr(common.dropdown.usageDirection),
  customDBSeqObjectType: () => tranformObjToArr(common.dropdown.customDBSeqObjectType),
  revenueActivityType: () => tranformObjToArr(common.dropdown.revenueActivityType),
  revenueJournalStatus: () => tranformObjToArr(common.dropdown.revenueJournalStatus),
  sourceSystem: () => tranformObjToArr(common.dropdown.sourceSystem),
  ratingDescription: () => tranformObjToArr(common.dropdown.ratingDescription),
  activityAction: () => tranformObjToArr(common.dropdown.activityAction),
  gatewayType: () => tranformObjToArr(common.dropdown.gatewayType),
  groupByType: () => tranformObjToArr(common.dropdown.groupByType),
  paymentReportCategory: () => tranformObjToArr(common.dropdown.paymentReportCategory),
  operativeStatus: () => tranformObjToArr(common.dropdown.operativeStatus),
  administrativeStatus: () => tranformObjToArr(common.dropdown.administrativeStatus),
  reportStatusItemService: () => tranformObjToArr(common.dropdown.reportStatusItemService),
  revenueReportObjectType: () => tranformObjToArr(common.dropdown.revenueReportObjectType),
  ebsExtractStatus: () => tranformObjToArr(common.dropdown.ebsExtractStatus),
  ebsExtractType: () => tranformObjToArr(common.dropdown.ebsExtractType),
  activitySource: () => tranformObjToArr(common.dropdown.activitySource),
  projectGatewayUrlType: () => tranformObjToArr(common.dropdown.projectGatewayUrlType),
  customerSupportGatewayUrlType: () => tranformObjToArr(common.dropdown.customerSupportGatewayUrlType),
  documentGatewayUrlType: () => tranformObjToArr(common.dropdown.documentGatewayUrlType),
  operationGatewayUrlType: () => tranformObjToArr(common.dropdown.operationGatewayUrlType),
  switchNameRawFileData: () => tranformObjToArr(common.dropdown.switchNameRawFileData),
  switchNameRawNonBroadsoft: () => tranformObjToArr(common.dropdown.switchNameRawNonBroadsoft),
  crmGatewayUrlType: () => tranformObjToArr(common.dropdown.crmGatewayUrlType),
  operatorFileStatus: () => tranformObjToArr(common.dropdown.operatorFileStatus),
  customAttributeCategory: () => tranformObjToArr(common.dropdown.customAttributeCategory),
  ladingType: () => tranformObjToArr(common.dropdown.ladingType),
  grantUnit: () => tranformObjToArr(common.dropdown.grantUnit),
  accumulatorUnit: () => tranformObjToArr(common.dropdown.accumulatorUnit),
  pacRecordStatus: () => tranformObjToArr(common.dropdown.pacRecordStatus),
  pacRecordType: () => tranformObjToArr(common.dropdown.pacRecordType),
  pacExtractType: () => tranformObjToArr(common.dropdown.pacExtractType),
  chargeTypeNoM: () => tranformObjToArr(common.dropdown.chargeTypeNoM),
  statusSaveAccountNonBillables: () => tranformObjToArr(common.dropdown.statusSaveAccountNonBillables),
  timeAndBillingObjectStatus: () => tranformObjToArr(common.dropdown.timeAndBillingObjectStatus),
  clientPricingUnit: () => tranformObjToArr(common.dropdown.clientPricingUnit),
  quarter: () => tranformObjToArrWithNoSort(common.dropdown.quarter),
  cutOffDOM: () => tranformObjToArrCutOfDom(common.dropdown.cutOffDOM),
  syncAPI: () => tranformObjToArr(common.dropdown.syncAPI),
  switchNameRawSmsFile: () => tranformObjToArr(common.dropdown.switchNameRawSmsFile),
  matchStatus: () => tranformObjToArr(common.dropdown.matchStatus),
  journalCreate: () => tranformObjToArr(common.dropdown.journalCreate),
  longDistanceType: () => tranformObjToArr(common.dropdown.longDistanceType),
  userType: () => tranformObjToArr(common.dropdown.userType),
  ERPExtractsAction: () => tranformObjToArr(common.dropdown.ERPExtractsAction),
  manualInvoiceStatus: () => tranformObjToArr(common.dropdown.manualInvoiceStatus),
  mergeSelectOptions: () => tranformObjToArr(common.dropdown.mergeSelectOptions),
  reratingBatchStatus: () => tranformObjToArr(common.dropdown.reratingBatchStatus),
  disperseSelectOptions: () => tranformObjToArr(common.dropdown.disperseSelectOptions),
  invoiceUnitStatus: () => tranformObjToArr(common.dropdown.invoiceUnitStatus),
  notesCrDrType: () => tranformObjToArr(common.dropdown.notesCrDrType),
  rerateReasonCodes: () => tranformObjToArr(common.dropdown.rerateReasonCodes),
  revenueObjectType: () => tranformObjToArr(common.dropdown.revenueObjectType),
  interfaceType: () => tranformObjToArr(common.dropdown.interfaceType),
  itemServiceStatus: () => tranformObjToArr(common.dropdown.itemServiceStatus),
  documentType: () => tranformObjToArr(common.dropdown.documentType),
  satCode: () => tranformObjToArr(common.dropdown.satCode),
  trafficType: () => tranformObjToArr(common.dropdown.trafficType),
  batchPaymentStatus: () => tranformObjToArr(common.dropdown.batchPaymentStatus),
  batchPaymentFileStatus: () => tranformObjToArr(common.dropdown.batchPaymentFileStatus),
  monthOfYearFull: () => tranformObjToArr(common.dropdown.monthOfYearFull),
  paymentSuspenseStatus: () => tranformObjToArr(common.dropdown.paymentSuspenseStatus),
  paymentActionHistory: () => tranformObjToArr(common.dropdown.paymentActionHistory),
  monthOfYearUpperCase: () => tranformObjToArrWithUpperCase(common.dropdown.monthOfYear),
  collectionStatus: () => tranformObjToArr(common.dropdown.collectionStatus),
  folioStatus: () => tranformObjToArr(common.dropdown.folioStatus),
  commitmentTerm: () => tranformObjToArr(common.dropdown.commitmentTerm),
  installmentStatus: () => tranformObjToArr(common.dropdown.installmentStatus),
  collectionReportGroupByType: () => tranformObjToArr(common.dropdown.collectionReportGroupByType),
  paymentAgeingReportGroupByType: () => tranformObjToArr(common.dropdown.paymentAgeingReportGroupByType),
  paymentActionSuspense: () => tranformObjToArr(common.dropdown.paymentActionSuspense),
  closeSuspenseReason: () => tranformObjToArr(common.dropdown.closeSuspenseReason),
  planStatus: () => tranformObjToArr(common.dropdown.planStatus),
  errorCodeProcessService: () => tranformObjToArr(common.dropdown.errorCodeProcessService),
  usageRecStatusFailedRecord: () => tranformObjToArr(common.dropdown.usageRecStatusFailedRecord),
  apiType: () => tranformObjToArr(common.dropdown.apiType),
  schedulePatternType: () => tranformObjToArrWithNoSort(common.dropdown.schedulePatternType),
  monthOfYearWithNumber: () => tranformObjToArrWithUpperCase(common.dropdown.monthOfYearWithNumber),
  arReasonCodeStatus: () => tranformObjToArr(common.dropdown.arReasonCodeStatus),
  arReasonCodeObjectType: () => tranformObjToArr(common.dropdown.arReasonCodeObjectType),
  fileRecordType: () => tranformObjToArr(common.dropdown.fileRecordType),
  scaleUnit: () => tranformObjToArr(common.dropdown.scaleUnit),
  folioType: () => tranformObjToArr(common.dropdown.folioType),
  omsTaskType: () => tranformObjToArr(common.dropdown.omsTaskType),
  quoteStatus: () => tranformObjToArr(common.dropdown.quoteStatus),
  quoteType: () => tranformObjToArr(common.dropdown.quoteType),
  trialType: () => tranformObjToArr(common.dropdown.trialType),
  evaluationComponent: () => tranformObjToArr(common.dropdown.evaluationComponent),
  comparisonOperator: () => tranformObjToArr(common.dropdown.comparisonOperator),
  transactionBalanceType: () => tranformObjToArr(common.dropdown.transactionBalanceType),
  provisioningStatus: () => tranformObjToArr(common.dropdown.provisioningStatus),
  accountCategory: () => tranformObjToArr(common.dropdown.accountCategory),
  serviceUnitStatus: () => tranformObjToArr(common.dropdown.serviceUnitStatus),
  quotePricingLevel: () => tranformObjToArr(common.dropdown.quotePricingLevel),
  subscriptionCategory: () => tranformObjToArr(common.dropdown.subscriptionCategory),
  termUnit: () => tranformObjToArr(common.dropdown.termUnit),
  merchantType: () => tranformObjToArr(common.dropdown.merchantType),
  genericStatus: () => tranformObjToArr(common.dropdown.genericStatus),
  creditLimitBreachActions: () => tranformObjToArr(common.dropdown.creditLimitBreachActions),
  additionalApiGetway: () => tranformObjToArr(common.dropdown.additionalApiGetway),
  taxConfigRegular: () => tranformObjToArr(common.dropdown.taxConfigRegular),
  webApiType: () => tranformObjToArr(common.dropdown.webApiType),
  zoneType: () => tranformObjToArr(common.dropdown.zoneType),
  taxType: () => tranformObjToArr(common.dropdown.taxType),
  messageType: () => tranformObjToArr(common.dropdown.messageType),
  notificationTemplateType: () => tranformObjToArr(common.dropdown.notificationTemplateType),
  transactionSource: () => tranformObjToArr(common.dropdown.transactionSource),
  collectionAction: () => tranformObjToArr(common.dropdown.collectionAction),
  arTaxRule: () => tranformObjToArr(common.dropdown.arTaxRule),
  arSource: () => tranformObjToArr(common.dropdown.arSource),
  exemptionLevel: () => tranformObjToArr(common.dropdown.exemptionLevel),
  sharingType: () => tranformObjToArr(common.dropdown.sharingType),
  apiProtocol: () => tranformObjToArr(common.dropdown.apiProtocol),
  paymentGatewayUrlType: () => tranformObjToArr(common.dropdown.paymentGatewayUrlType),
  collectionActionWorkingDay: () => tranformObjToArr(common.dropdown.collectionActionWorkingDay),
  templateType: () => tranformObjToArr(common.dropdown.templateType),
  templateFileType: () => tranformObjToArr(common.dropdown.templateFileType),
  templateFileStatus: () => tranformObjToArr(common.dropdown.templateFileStatus),
  invoiceSummaryType: () => tranformObjToArr(common.dropdown.invoiceSummaryType),
  billUnitStatus: () => tranformObjToArr(common.dropdown.billUnitStatus),
  activityType: () => tranformObjToArr(common.dropdown.activityType),
  expensePeriodUnit: () => tranformObjToArr(common.dropdown.expensePeriodUnit),
  moveProvisioningTypeActions: () => tranformObjToArr(common.dropdown.moveProvisioningTypeActions),
  quoteSource: () => tranformObjToArr(common.dropdown.quoteSource),
  bonusPeriodUnit: () => tranformObjToArr(common.dropdown.bonusPeriodUnit),
  objectFileType: () => tranformObjToArr(common.dropdown.objectFileType),
  scheduleFrequency: () => tranformObjToArr(common.dropdown.scheduleFrequency),
});

export default selections;
