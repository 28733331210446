import gql from 'graphql-tag';
import parseOrderMutation from './parseOrderMutation';

export default data => {
  const dataModify = parseOrderMutation(data, [
    'orderType',
    'index',
    'apiName',
    'apiCategory',
    'status',
    'onErrorResubmit',
    'validateOnly',
    'level',
    'action',
    'provisioningSequence',
    'merchantName',
    'synchronousResponse',
    'merchantName',
    'executeOnError',
    'errorApiName',
    'waitTimeBeforeNext',
    'onManualProvisioningOnly',
    'retryCountOnError',
    'restartApiOnError',
    'manualProvisioningApi',
  ]);
  const modifyProvSequenceConfig = `modifyProvSequenceConfig(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyProvSequenceConfig} {
      id
    }
  }
`;
};
