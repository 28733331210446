import gql from 'graphql-tag';

export default () => gql`
  query {
    getTaxConfig(type: "PRODUCT_CODES") {
      id
      productCodes {
        index
        productCode
        jurisdiction
        jurisdictionList
        excludeList
        description
        startDate
        endDate
        taxPercent
        minimumThreshold
        maximumThreshold
        additionalTaxType
        additionalTaxDescription
        additionalTaxRate
        taxCategory
        productTaxes {
          index
          name
          taxBase
          jurisdiction
          jurisdictionList
          excludeList
          description
          startDate
          endDate
          taxPercent
          minimumThreshold
          maximumThreshold
          accumulatorId
          minAccumulatorThreshold
          maxAccumulatorThreshold
        }
      }
    }
  }
`;
